<template>
  <page-header-wrapper>
    <div class="a-card">
      <a-form :form="couponForm">
        <a-card :body-style="{ padding: '24px 32px' }" :bordered="false">
          <a-tabs default-active-key="1">
            <a-tab-pane :forceRender="true" key="1" :tab="$t('title.basicInfo')">
              <a-form-item
                has-feedback
                :label="$t('field.couponTypeName')"
                v-bind="formItemLayout"
              >
                <a-select
                  v-decorator="[
                    'couponTypeCode',
                    {
                      getValueFromEvent: onCouponTypeChanged,
                      rules: [{ required: true, message: '请输入' + this.$t('field.couponTypeName') }]
                    }
                  ]"
                  mode="default"
                  name="couponTypeName"
                  :disabled="data.couponId"
                >
                  <a-select-option
                    v-for="(item, index) in codeList.couponTypeList"
                    :key="index"
                    :item="item"
                    :value="item.couponTypeCode"
                    >{{ item.couponTypeCode }}-{{ item.couponTypeName }}
                  </a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item
                has-feedback
                :label="$t('field.couponCode')"
                v-bind="formItemLayout"
              >
                <a-input
                  v-decorator="[
                    'couponCodeInput',
                    {
                      rules: $rules([
                        { required: true, message: '请输入' + this.$t('field.couponCode') },
                        { max: 8, message: this.$t('field.campaignCode') + '长度小于等于8' },
                        {
                          type: 'checkExists',
                          url: '/api/marketing/coupon/checkCouponCodeExists',
                          existIdValue: data.couponId,
                          checkField: 'couponCode',
                          idFieldName: 'couponId',
                          tableName: 'coupon',
                          checkFieldBaseValue: data.couponTypeCode
                        }
                      ])
                    }
                  ]"
                  name="data.couponCodeInput"
                  @change="dataModel($event, 'data.couponCodeInput')"
                  :placeholder="'请输入' + this.$t('field.couponCode')"
                  :maxLength="8"
                />
              </a-form-item>
              <a-form-item
                has-feedback
                :label="$t('field.couponName')"
                v-bind="formItemLayout"
              >
                <a-input
                  v-decorator="[
                    'couponName',
                    {
                      rules: $rules([
                        { required: true, message: '请输入' + this.$t('field.couponCode') },
                        { max: 100, message: this.$t('field.couponCode') + '长度小于等于100' },
                        {
                          type: 'checkExists',
                          url: '/api/marketing/coupon/checkCouponNameExists',
                          existIdValue: data.couponId,
                          checkField: 'couponName',
                          idFieldName: 'couponId',
                          tableName: 'coupon'
                        }
                      ])
                    }
                  ]"
                  name="data.couponName"
                  @change="dataModel($event, 'data.couponName')"
                  :placeholder="'请输入' + this.$t('field.couponName')"
                />
              </a-form-item>
              <a-form-item
                has-feedback
                :label="$t('field.timeLimitType')"
                v-bind="formItemLayout"
              >
                <a-select
                  v-decorator="[
                    'timeLimitType',
                    { rules: [{ required: true, message: '请输入' + this.$t('field.timeLimitType') }] }
                  ]"
                  mode="default"
                  v-model="data.timeLimitType"
                >
                  <a-select-option v-for="(item, index) in codeList.timeLimitType" :key="index" :value="item.label"
                    >[{{ item.value }}] {{ item.label }}
                  </a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item
                has-feedback
                :label="$t('field.validTimeRange')"
                v-bind="formItemLayout"
                v-if="data.timeLimitType === '固定期间'"
              >
                <a-range-picker
                  v-decorator="[
                    'fixedDT',
                    { rules: [{ type: 'array', required: true, message: '请输入' + this.$t('field.validTimeRange') }] }
                  ]"
                  showTime
                  v-model="fixedDT"
                  valueFormat="YYYYMMDDHHmmss"
                />
              </a-form-item>
              <a-form-item
                has-feedback
                :label="$t('field.endTime')"
                v-bind="formItemLayout"
                v-if="data.timeLimitType === '固定截止时间'"
              >
                <a-date-picker
                  v-decorator="[
                    'timeLimitDT',
                    { rules: [{ type: 'string', required: true, message: '请输入' + this.$t('field.endTime') }] }
                  ]"
                  showTime
                  v-model="timeLimitDT"
                  valueFormat="YYYYMMDDHHmmss"
                />
              </a-form-item>
              <a-form-item
                has-feedback
                :label="$t('field.validDateCount')"
                v-bind="formItemLayout"
                v-if="data.timeLimitType === '领取后生效'"
              >
                <a-input
                  v-decorator="[
                    'validDateCount',
                    { rules: [{ required: true, message: '请输入' + this.$t('field.validDateCount') }] }
                  ]"
                  v-if="!validDateFlg"
                  :addonAfter="$t('day')"
                  name="data.validDateCount "
                  v-model="data.validDateCount"
                  :placeholder="'请输入' + this.$t('field.validDateCount')"
                />
                
              </a-form-item>
              <a-form-item
                has-feedback
                :label="$t('field.validDateCount')+'限制'"
                v-bind="formItemLayout"
                v-if="0 && data.timeLimitType === '领取后生效'"
              >
                <a-checkbox v-model="validDateFlg">无限制</a-checkbox>
              </a-form-item>
              <a-form-item
                v-if="data.couponId"
                :label="$t('field.couponStatus')"
                v-bind="formItemLayout"
              >
                {{ data.couponStatus }}
              </a-form-item>
              <SelectMarketplace :data="data" />
              <!--              <SelectSiteList-->
              <!--                :site-list="data.siteList"-->
              <!--                :site-code-list="data.siteCodeList"-->
              <!--                :search="data.marketplaceCode"-->
              <!--                @siteDataChange="siteDataChange"/>-->
            </a-tab-pane>

            <a-tab-pane :forceRender="true" key="2" :tab="$t('title.discountSetting')">
              <a-form-item
                :label="$t('field.marketingDiscountType')"
                v-bind="formItemLayout"
              >
                {{ data.marketingDiscountType }}
              </a-form-item>
              <a-form-item
                has-feedback
                v-if="data.marketingDiscountType === '运费比例'"
                :label="$t('field.shippingFeeAmountPercent')"
                v-bind="formItemLayout"
              >
                <a-input
                  type="number"
                  v-decorator="[
                    'discount.shippingFeeAmountPercent',
                    {
                      rules: $rules([
                        { required: true, message: '请输入' + this.$t('field.shippingFeeAmountPercent') },
                        {
                          type: 'maxValue',
                          maxValue: 100,
                          message: this.$t('field.shippingFeeAmountPercent') + '应小于等于100'
                        }
                      ])
                    }
                  ]"
                  name="data.discount.shippingFeeAmountPercent"
                  v-model="data.discount.shippingFeeAmountPercent"
                  :placeholder="'请输入' + this.$t('field.shippingFeeAmountPercent')"
                />
              </a-form-item>
              <a-form-item
                has-feedback
                v-if="data.marketingDiscountType === '商品价格比例'"
                :label="$t('field.pricePercent')"
                v-bind="formItemLayout"
              >
                <a-input
                  v-decorator="[
                    'discount.pricePercent',
                    {
                      rules: $rules([
                        { required: true, message: '请输入' + this.$t('field.pricePercent') },
                        { type: 'maxValue', maxValue: 100, message: this.$t('field.pricePercent') + '应小于等于100' }
                      ])
                    }
                  ]"
                  type="number"
                  addon-after="%"
                  name="data.discount.pricePercent"
                  v-model="data.discount.pricePercent"
                  :placeholder="'请输入' + this.$t('field.pricePercent')"
                />
              </a-form-item>
              <a-form-item
                has-feedback
                v-if="data.marketingDiscountType === '运费金额'"
                :label="$t('field.shippingFeeAmount')"
                v-bind="formItemLayout"
              >
                <a-input
                  type="number"
                  v-decorator="[
                    'discount.shippingFeeAmountShow',
                    { rules: [{ required: true, message: '请输入' + this.$t('field.shippingFeeAmount') }] }
                  ]"
                  step="0.01"
                  name="data.discount.shippingFeeAmount"
                  v-model="data.discount.shippingFeeAmount"
                  :addon-after="currencySymbol"
                  :placeholder="'请输入' + this.$t('field.shippingFeeAmount')"
                />
              </a-form-item>
              <a-form-item
                has-feedback
                v-if="data.marketingDiscountType === '商品价格金额'"
                :label="$t('field.priceAmount')"
                v-bind="formItemLayout"
              >
                <a-input-group size="large">
                  <a-row :gutter="8">
                    <a-col :span="12">
                      <a-input
                        :min="0"
                        type="number"
                        step="1"
                        prefix="满"
                        v-decorator="[
                          'discount.minTotalAmountShow',
                          {
                            rules: [
                              { required: true, message: '请输入' + this.$t('field.minTotalAmount') }
                              // { type: 'number', message: this.$t('field.priceAmount')+'应该是数字' }
                            ]
                          }
                        ]"
                        :addon-after="currencySymbol"
                        name="data.discount.minTotalAmount"
                        @change="dataModel($event, 'data.discount.minTotalAmount')"
                        :placeholder="'请输入' + this.$t('field.minTotalAmount')"
                      />
                    </a-col>
                    <a-col :span="12">
                      <a-input
                        :min="1"
                        prefix="减"
                        :addon-after="currencySymbol"
                        v-decorator="[
                          'discount.priceAmountShow',
                          {
                            rules: [
                              { required: true, message: '请输入' + this.$t('field.priceAmount') }
                              // { type: 'number', message: this.$t('field.priceAmount')+'应该是数字' }
                            ]
                          }
                        ]"
                        name="data.discount.priceAmount"
                        @change="dataModel($event, 'data.discount.priceAmount')"
                        :placeholder="'请输入' + this.$t('field.priceAmount')"
                      />
                    </a-col>
                  </a-row>
                </a-input-group>
              </a-form-item>
            </a-tab-pane>
            <!--            <a-tab-pane :forceRender="true" key="3" :tab="$t('title.conditionSetting')">-->
            <!--              <a-form-item-->
            <!--                :label="$t('field.marketingConditionType')"-->
            <!--                :labelCol="{lg: {span: 7}, sm: {span: 7}}"-->
            <!--                :wrapperCol="{lg: {span: 10}, sm: {span: 17} }">-->
            <!--                {{ data.marketingConditionType }}-->
            <!--              </a-form-item>-->
            <!--              <a-form-item-->
            <!--                v-if="data.marketingConditionType === '品牌'-->
            <!--                  ||data.marketingConditionType === '品牌SPU'||data.marketingConditionType === '品牌SKU'-->
            <!--                  ||data.marketingConditionType === '品类品牌SPU'||data.marketingConditionType === '品类品牌SKU'"-->
            <!--                :label="$t('field.brandCode')"-->
            <!--                :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"-->
            <!--                :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"-->
            <!--              >-->
            <!--                <a-select v-model="data.condition.brandCode" mode="default" @change="onBrandChanged">-->
            <!--                  <a-select-option-->
            <!--                    v-for="(item, index) in codeList.brandCode"-->
            <!--                    :key="index"-->
            <!--                    :brandName="item.brandName"-->
            <!--                    :value="item.brandCode"-->
            <!--                  >{{ item.brandCode }}-{{ item.brandName }}-->
            <!--                  </a-select-option>-->
            <!--                </a-select>-->
            <!--              </a-form-item>-->
            <!--              <a-form-item-->
            <!--                v-if="data.marketingConditionType === '品类SPU'||data.marketingConditionType === '品类SKU'-->
            <!--                  ||data.marketingConditionType === '品类品牌SPU'||data.marketingConditionType === '品类品牌SKU'"-->
            <!--                :label="$t('field.categoryCode')"-->
            <!--                :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"-->
            <!--                :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"-->
            <!--              >-->
            <!--                <a-select v-model="data.condition.categoryCode" mode="default" @change="onCategoryChanged">-->
            <!--                  <a-select-option-->
            <!--                    v-for="(item, index) in codeList.categoryCode"-->
            <!--                    :key="index"-->
            <!--                    :categoryId="item.categoryId"-->
            <!--                    :serviceList="item.serviceList"-->
            <!--                    :categoryName="item.categoryName"-->
            <!--                    :value="item.categoryCode"-->
            <!--                  >{{ item.categoryCode }}-{{ item.categoryName }}-->
            <!--                  </a-select-option>-->
            <!--                </a-select>-->
            <!--              </a-form-item>-->
            <!--              <SpuListEdit-->
            <!--                v-if="data.marketingConditionType === '品牌SPU'||data.marketingConditionType === '品类SPU'||data.marketingConditionType === '品类品牌SPU'-->
            <!--                  ||data.marketingConditionType === 'SPU'"-->
            <!--                :condition="data.condition"-->
            <!--                :spu-list="data.condition.spuList"-->
            <!--                @handleSpuSelectedOk="handleSpuSelectedOk"></SpuListEdit>-->
            <!--              <SkuListEdit-->
            <!--                v-if="data.marketingConditionType === '品牌SKU'-->
            <!--                  ||data.marketingConditionType === '品类SKU'||data.marketingConditionType === '品类品牌SKU'-->
            <!--                  ||data.marketingConditionType === 'SKU'"-->
            <!--                :condition="data.condition"-->
            <!--                :sku-list="data.condition.skuList"></SkuListEdit>-->
            <!--            </a-tab-pane>-->
            <a-tab-pane :forceRender="true" key="4" :tab="$t('title.multilingual')">
              <MultilingualInfo
                :data="data.multilingualInfoList"
                :edit="true"
                @dataChange="getMultilingualData"
              ></MultilingualInfo>
            </a-tab-pane>
            <!--            <a-tab-pane :forceRender="true" key="5" :tab="$t('title.picSetting')">-->
            <!--              <PicUrlEdit field-name="listPicUrl" :data="data" :style="{marginBottom:'10px'}" ></PicUrlEdit>-->
            <!--              <PicUrlListEdit field-name="picList" :picUrlList="data.picList" :style="{marginBottom:'10px'}" ></PicUrlListEdit>-->
            <!--              <PicDisplayListEdit-->
            <!--                field-name="detailPicDisplayList"-->
            <!--                :style="{marginBottom:'10px'}"-->
            <!--                :pic-display-list="data.detailPicDisplayList"></PicDisplayListEdit>-->
            <!--            </a-tab-pane>-->
          </a-tabs>
        </a-card>
        <a-form-item :wrapperCol="{ span: 24 }" style="text-align: center">
          <a-button style="margin-left: 8px" type="primary" @click="handleCouponSubmit" icon="save">{{
            $t('button.submit')
          }}</a-button>
        </a-form-item>
      </a-form>
    </div>
  </page-header-wrapper>
</template>

<script>
import { listCategory } from '@/api/goods/category'
import { listBrand } from '@/api/goods/brand'
import { listCouponType } from '@/api/marketing/couponType'
import { getCouponData, saveCouponData } from '@/api/marketing/coupon'
import MultilingualInfo from '@/views/marketing/components/MultilingualInfo'
import moment from 'moment'
import SelectMarketplace from '@/views/component/SelectMarketplace'
import SelectSiteList from '@/views/component/SelectSiteList'
import SpuListEdit from '@/views/component/SpuListEdit'
import SkuListEdit from '@/views/component/SkuListEdit'
import PicUrlEdit from '@/views/component/PicUrlEdit'
import PicUrlListEdit from '@/views/component/PicUrlListEdit'
import PicDisplayListEdit from '@/views/component/PicDisplayListEdit'
import { listMarketplace } from '@/api/system/marketplace'

export default {
  name: 'EditCoupon',
  props: {},
  components: {
    MultilingualInfo,
    SelectMarketplace,
    SelectSiteList,
    SpuListEdit,
    SkuListEdit,
    PicUrlEdit,
    PicUrlListEdit,
    PicDisplayListEdit
  },
  data() {
    return {
      formItemLayout: {
        labelCol:{ lg: { span: 7 }, sm: { span: 7 } },
        wrapperCol: { lg: { span: 10 }, sm: { span: 17 } }
      },
      currencySymbol: '',
      moment,
      couponForm: this.$form.createForm(this),
      codeList: {
        brandCode: [],
        categoryCode: [],
        couponTypeList: []
      },
      skuCondition: {
        isFirstSku: 1
      },
      spuCondition: {},
      data: {
        timeLimitType: null,
        couponStatus: '草拟中',
        marketplaceCode: '',
        siteList: [],
        siteCodeList: [],
        discount: {
          minTotalAmount: 0,
          pricePercent: 0,
          shippingFeeAmountPercent: 0,
          priceAmount: 0,
          shippingFeeAmount: 0
        },
        condition: {
          skuList: [],
          spuList: []
        },
        multilingualInfoList: []
      },
      startTime: [],
      fixedDT: [],
      timeLimitDT: '',
      validDateFlg: false,
      selectedCouponType: {}
    }
  },
  mounted() {
    console.log(this.$route.query.type)
    this.pageType = this.$route.query.type
    if (this.$route.query.type === 'edit') {
      this.getCouponData({ couponId: this.$route.query.couponId })
    } else {
    }
    listCouponType({}).then(res => {
      this.codeList.couponTypeList = res.data
    })
    // this.listCategory()
    // this.listBrand()
    // this.listSpu()
    // this.listSku()
    this.getCurrencySymbol()
  },
  methods: {
    dataModel(e, str) {
      let arr = str.split('.');
      this.setDataValue(this, arr, e.target.value)
    },
    getCurrencySymbol() {
      listMarketplace({}).then(res => {
        const marketplaceCode = this.data.marketplaceCode
        const list = res.data.filter(item => item.marketplaceCode === marketplaceCode)
        const country = (list[0] || {}).country || ''
        this.currencySymbol = this.getCode('currencySymbol', country)
      })
    },
    // 编辑回显数据
    getCouponData(data) {
      const that = this
      getCouponData(data)
        .then(res => {
          if (!res.condition) {
            res.condition = {
              skuList: [],
              spuList: []
            }
          }
          if (!res.condition.spuList) {
            res.condition.spuList = []
          }
          if (!res.condition.skuList) {
            res.condition.skuList = []
          }
          if (!res.discount) {
            res.discount = {
              minTotalAmount: 0,
              pricePercent: 0,
              shippingFeeAmountPercent: 0,
              priceAmount: 0,
              shippingFeeAmount: 0
            }
          } else {
            if (res.discount.minTotalAmount || res.discount.minTotalAmount === 0) {
              res.discount.minTotalAmountShow = res.discount.minTotalAmount / 100 || '0'
            }
            if (res.discount.priceAmount) {
              res.discount.priceAmountShow = res.discount.priceAmount / 100
            }
            if (res.discount.shippingFeeAmount) {
              res.discount.shippingFeeAmountShow = res.discount.shippingFeeAmount / 100
            }
          }
          if (!res.picList) {
            res.picList = []
          }
          if (!res.detailPicDisplayList) {
            res.detailPicDisplayList = []
          }
          if (res.timeLimitType === '固定期间') {
            this.fixedDT = [res.startTimeDate + '' + res.startTimeTime, res.endTimeDate + '' + res.endTimeTime]
            res.fixedDT = this.fixedDT
          } else if (res.timeLimitType === '固定截止时间') {
            this.timeLimitDT = res.endTimeDate + '' + res.endTimeTime
            res.timeLimitDT = this.timeLimitDT
          } else if (res.timeLimitType === '领取后生效') {
            if (res.validDateCount === 0) {
              this.validDateFlg = true
              res.validDateFlg = this.validDateFlg
            }
          }
          that.data = res
          this.getCurrencySymbol()
          this.$nextTick(() => {
            const formData = {}
            const fieldsValue = that.couponForm.getFieldsValue()
            Object.keys(fieldsValue).map(key => (formData[key] = res[key]))
            that.couponForm.setFieldsValue(formData)
            console.log('获取优惠券数据成功', data, res, that.couponForm)
          })
        })
        .catch(res => {
          console.log('获取数据失败', res)
        })
    },
    // handler
    handleCouponSubmit(event) {
      console.log('handleCouponSubmit', event, this.data)
      const that = this
      this.couponForm.validateFieldsAndScroll((err, values) => {
        console.log('handleCouponSubmit-errors', err)
        if (err && Object.keys(err).length > 0) {
          this.alertError(err)
          return
        }
        if (this.data.timeLimitType === '固定期间') {
          this.data.startTimeTime = this.fixedDT[0].substr(8)
          this.data.startTimeDate = this.fixedDT[0].substr(0, 8)
          this.data.endTimeTime = this.fixedDT[1].substr(8)
          this.data.endTimeDate = this.fixedDT[1].substr(0, 8)
        } else if (this.data.timeLimitType === '固定截止时间') {
          this.data.endTimeTime = this.timeLimitDT.substr(8)
          this.data.endTimeDate = this.timeLimitDT.substr(0, 8)
        } else if (this.data.timeLimitType === '领取后生效') {
          if (this.validDateFlg) {
            this.data.validDateCount = 0
          }
        }
        const request = Object.assign({ ...that.data, ...that.couponForm.getFieldsValue() })
        if (request.discount.minTotalAmountShow) {
          request.discount.minTotalAmount = request.discount.minTotalAmountShow * 100
        }
        if (request.discount.priceAmountShow) {
          request.discount.priceAmount = request.discount.priceAmountShow * 100
        }
        if (request.discount.shippingFeeAmountShow) {
          request.discount.shippingFeeAmount = request.discount.shippingFeeAmountShow * 100
        }
        saveCouponData(request)
          .then(res => {
            console.log('saveCouponData:', that.data, res)
            that.$message.success('操作成功')
            this.$router.push({
              path: '/marketing/CouponList',
              name: 'CouponList'
            })
          })
          .catch(res => {
            that.$message.error('操作失败,' + res.message)
          })
      })
    },
    // 品牌下拉框
    listBrand() {
      const that = this
      listBrand({})
        .then(res => {
          // console.log(res)
          that.codeList.brandCode = res.data
        })
        .catch(res => {
          // console.log(res)
        })
    },
    onBrandChanged(value, option) {
      console.log(option.data.attrs.brandName)
      this.data.condition.brandCode = value
      this.data.condition.brandName = option.data.attrs.brandName
    },
    // 管理分类下拉框
    listCategory() {
      const that = this
      listCategory({})
        .then(res => {
          // console.log(res)
          that.codeList.categoryCode = res.data
        })
        .catch(res => {
          // console.log(res)
        })
    },
    // 管理分类下拉框
    onCategoryChanged(value, option) {
      console.log(value)
      this.data.condition.categoryCode = value
      this.data.condition.categoryName = option.data.attrs.categoryName
      this.data.condition.categoryId = option.data.attrs.categoryId
    },
    checkStartTm(dates, dateString) {
      console.log(dates, dateString)
      console.log(this.startTime)
    },
    onCouponTypeChanged(value, option) {
      if (!value || !option) {
        return ''
      }
      console.log('onCouponTypeChanged', value, option.data.attrs.item)
      this.data.couponTypeId = value
      this.data.couponTypeCode = option.data.attrs.item.couponTypeCode
      this.data.couponTypeName = option.data.attrs.item.couponTypeName
      this.data.marketingConditionType = option.data.attrs.item.marketingConditionType
      this.data.marketingDiscountType = option.data.attrs.item.marketingDiscountType
      this.selectedCouponType = option.data.attrs.item
      return value
    },
    siteDataChange(data) {
      console.log(data)
      this.data.siteCodeList = data
    },
    getMultilingualData(data) {
      this.data.multilingualInfoList = data
    }
  }
}
</script>
<style scoped>
.ant-select {
  width: 100%;
}
</style>
