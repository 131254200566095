<template>
  <a-card :title="$t('field.'+fieldName)">
    <a-upload
      :before-upload="beforeUploadPicUrlList"
      :customRequest="httpRequestPicUrlList"
      :file-list="fileList"
      :multiple="true"
      list-type="picture-card"
      @change="handlePicUrlListChange"
    >
      <div v-if="fileList.length < 10">
        <a-icon type="plus"/>
        <div class="ant-upload-text">
          {{ $t('message.uploadPic') }}
        </div>
      </div>
    </a-upload>
  </a-card>
</template>
<script>

import { imgUpload } from '@/api/commonApi'

export default {
  name: 'PicUrlListEdit',
  props: {
    picUrlList: {
      type: Array
    },
    fieldName: {
      type: String
    }
  },
  data () {
    return {
      changed: false,
      fileList: []
    }
  },
  mounted () {
    if (!this.changed && this.picUrlList && this.picUrlList.length > 0) {
      this.picUrlList.map((item, index) => {
        this.fileList.push({
          uid: index,
          name: `image${index}.png`,
          status: 'done',
          url: item
        })
      })
    }
  },
  methods: {
    beforeUploadPicUrlList (file) {
      return true
    },
    httpRequestPicUrlList (data) {
      const formData = new FormData()
      if (data.file != null) {
        formData.append('file', data.file)
      }
      const that = this
      imgUpload(formData).then((res) => {
        console.log('上传成功', res)
        this.$notification['success']({
          message: '上传成功',
          description: res.url
        })
        that.fileList[that.fileList.length - 1].url = res.url
        that.fileList[that.fileList.length - 1].status = 'done'
        that.picUrlList.push(res.url)
      }).catch((res) => {
        console.log('上传失败', res)
        this.$notification['error']({
          message: '上传失败',
          description:
            "<img src='" + res.url + "'>"
        })
      })
    },
    // 图片上传
    handlePicUrlListChange (info) {
      this.changed = true
      this.fileList = info.fileList
      this.picUrlList.splice(0, this.picUrlList.length)
      this.fileList.map((item, index, array) => {
        if (item.url && item.url !== '') {
          this.picUrlList.push(item.url)
        }
      })
      console.log('handlePicUrlListChange', info, this.picUrlList)
    },
    updatePicUrlList () {
      this.picUrlList.splice(0, this.picUrlList.length)
      this.fileList.map((item, index, array) => {
        this.picUrlList.push(item.url)
      })
      console.log('updatePicUrlList', this.picUrlList)
    }
  },
  computed: {
    initPicUrlList () {
      return this.picUrlList
    }
  },
  watch: {
    initPicUrlList (value) {
      console.debug('init picUrl List', value, this.picUrlList)
      if (!this.changed && this.picUrlList && this.picUrlList.length > 0) {
        this.picUrlList.map((item, index) => {
          this.fileList.push({
            uid: index,
            name: `image${index}.png`,
            status: 'done',
            url: item
          })
        })
      }
    }
  }
}
</script>
<style>
.ant-select {
  width: 100%;
}

.avatar-uploader > .ant-upload {
  width: 128px;
  height: 128px;
}

.ant-upload-select-picture i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture .ant-upload-text {
  margin-top: 8px;
  color: #666;
}

/* you can make up upload button and sample style by using stylesheets */
.ant-upload-select-picture i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture .ant-upload-text {
  margin-top: 8px;
  color: #666;
}

.avatar-uploader > .ant-upload {
  width: 128px;
  height: 128px;
}

.ant-upload-select-picture i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>
