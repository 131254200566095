<template>
  <a-card title="优惠券多语言设置">
    <div slot="extra">
      <a-button icon="plus" style="float: right" type="link" @click="handleAdd()" v-if="edit"
        >{{ $t('button.add') }}
      </a-button>
    </div>
    <a-tabs :default-active-key="data && data.length > 0 ? data[0].language : ''">
      <template v-for="languageInfo in data">
        <a-tab-pane
          :tab="getCode('languageName', languageInfo.language)"
          style="width: 100%"
          v-if="data && data.length > 0"
          :key="languageInfo.language"
          :title="getCode('languageName', languageInfo.language)"
        >
          <div slot="extra">
            <a-popconfirm :title="$t('msg.confirmDelete')" @confirm="() => onDelete(record.language)">
              <a href="javascript:;">{{ $t('button.delete') }}</a>
            </a-popconfirm>
          </div>
          <a-form-item
            :label="$t('field.displayName')"
            :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
            :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
          >
            <!--            v-decorator="[-->
            <!--              'displayName',-->
            <!--              {-->
            <!--                rules: [-->
            <!--                  { required: true, message: '请输入' + $t('field.displayName') },-->
            <!--                  { max: 100, message: $t('field.displayName') + '长度小于等于100' },-->
            <!--                ],-->
            <!--              },-->
            <!--            ]"-->
            <a-input
              name="displayName"
              v-model="languageInfo.displayName"
              :placeholder="'请输入' + $t('field.displayName')"
            />
          </a-form-item>
          <!--        <a-form-item-->
          <!--          :label="$t('field.displayIntro')"-->
          <!--          :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"-->
          <!--          :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"-->
          <!--        >-->
          <!--          &lt;!&ndash;            v-decorator="[&ndash;&gt;-->
          <!--          &lt;!&ndash;              'displayIntro',&ndash;&gt;-->
          <!--          &lt;!&ndash;              {&ndash;&gt;-->
          <!--          &lt;!&ndash;                rules: [&ndash;&gt;-->
          <!--          &lt;!&ndash;                  { required: true, message: '请输入' + $t('field.displayIntro') },&ndash;&gt;-->
          <!--          &lt;!&ndash;                  { max: 100, message: $t('field.displayIntro') + '长度小于等于100' },&ndash;&gt;-->
          <!--          &lt;!&ndash;                ],&ndash;&gt;-->
          <!--          &lt;!&ndash;              },&ndash;&gt;-->
          <!--          &lt;!&ndash;            ]"&ndash;&gt;-->
          <!--          <a-textarea-->
          <!--            name="displayIntro"-->
          <!--            v-model="languageInfo.displayIntro"-->
          <!--            :placeholder="'请输入' + $t('field.displayIntro')"-->
          <!--          />-->
          <!--        </a-form-item>-->
          <!--        <PicUrlEdit field-name="listPicUrl" :data="languageInfo" :style="{marginBottom:'10px'}"></PicUrlEdit>-->
          <!--        <PicUrlEdit field-name="usedPicUrl" :data="languageInfo" :style="{marginBottom:'10px'}"></PicUrlEdit>-->
          <!--        <PicUrlEdit field-name="expiredPicUrl" :data="languageInfo" :style="{marginBottom:'10px'}"></PicUrlEdit>-->
          <!--        <PicUrlListEdit field-name="picList" :pic-url-list="languageInfo.picList" :style="{marginBottom:'10px'}"></PicUrlListEdit>-->
          <!--        <PicDisplayListEdit-->
          <!--          field-name="detailPicDisplayList"-->
          <!--          :pic-display-list="languageInfo.detailPicDisplayList"></PicDisplayListEdit>-->
          <!--      </a-tab-pane>-->
        </a-tab-pane>
      </template>
    </a-tabs>
    <a-modal
      :confirm-loading="false"
      :title="$t('page.multilingualInfo')"
      :visible="modalShow"
      @cancel="handleEditCancel"
      @ok="handleSelectedOk"
    >
      <a-form-item
        :label="$t('field.language')"
        :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
        :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
      >
        <a-select mode="default" style="width: 200px" v-model="modalData.language">
          <a-select-option v-for="(item, index) in codeList.language" :key="index" :value="item.value"
            >{{ item.label }}
          </a-select-option>
        </a-select>
      </a-form-item>
    </a-modal>
  </a-card>
</template>

<script>
import PicUrlEdit from '@/views/component/PicUrlEdit'
import PicUrlListEdit from '@/views/component/PicUrlListEdit'
import PicDisplayListEdit from '@/views/component/PicDisplayListEdit'

export default {
  name: 'MultilingualInfo',
  components: {
    PicUrlEdit,
    PicUrlListEdit,
    PicDisplayListEdit
  },
  props: {
    data: {
      type: Array
    },
    edit: {
      type: Boolean
    }
  },
  data() {
    return {
      modalShow: false, // 弹框是否展示
      modalData: {
        language: ''
      } // 弹框数据
    }
  },
  methods: {
    // 关闭弹框
    handleEditCancel() {
      // 弹出编辑页面
      this.modalShow = false
      this.modalData = {
        language: ''
      }
    },
    // 确认弹框
    handleSelectedOk() {
      console.log('handleSelectedOk', this.modalData)
      // 弹出编辑页面
      let language = this.modalData.language;
      if(!language) {
        return this.$message.warning('请选择有效的多语言选项！')
      }
      if(this.data.filter(function(el) { return el.language == language}).length) {
        return this.$message.warning('当前选中多语言已添加过，请重新选择！')
      }
      this.data.push({ language })
      // 弹出编辑页面
      this.modalShow = false
      this.modalData = {
        language: ''
      }
    },
    // 新增弹框
    handleAdd() {
      this.modalShow = true
    },
    onDelete(language) {
      const list = this.data.filter(item => item.language !== language)
      this.data.splice(0, this.data.length)
      list.forEach(item => {
        this.data.push(item)
      })
    }
  }
}
</script>
