import request from '@/utils/request'

const baseUrl = '/api'

export function saveBrandData (parameter) {
  let url = baseUrl + '/goods/brand/save'
  if (!parameter.brandId) {
    url = baseUrl + '/goods/brand/create'
  }
  return request({
    url: url,
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}

export function searchBrand (parameter) {
  return request({
    url: baseUrl + '/goods/brand/search',
    method: 'post',
    data: parameter
  }).then(res => {
    return {
      data: res.data.list,
      pageNo: res.data.pageInfo.pageNo ? res.data.pageInfo.pageNo : 1,
      totalCount: res.data.pageInfo.totalCount
    }
  })
}

export function listBrand (parameter) {
  return request({
    url: baseUrl + '/goods/brand/list',
    method: 'post',
    data: parameter
  }).then(res => {
    return {
      data: res.data
    }
  })
}

export function getBrandData (parameter) {
  return request({
    url: baseUrl + '/goods/brand/get',
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}

export function enableBrand (parameter) {
  return request({
    url: baseUrl + '/goods/brand/enable',
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}

export function disableBrand (parameter) {
  return request({
    url: baseUrl + '/goods/brand/disable',
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}

export function deleteBrand (parameter) {
  return request({
    url: baseUrl + '/goods/brand/delete',
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}
