var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('page-header-wrapper',[_c('div',{staticClass:"a-card"},[_c('a-form',{attrs:{"form":_vm.couponForm}},[_c('a-card',{attrs:{"body-style":{ padding: '24px 32px' },"bordered":false}},[_c('a-tabs',{attrs:{"default-active-key":"1"}},[_c('a-tab-pane',{key:"1",attrs:{"forceRender":true,"tab":_vm.$t('title.basicInfo')}},[_c('a-form-item',_vm._b({attrs:{"has-feedback":"","label":_vm.$t('field.couponTypeName')}},'a-form-item',_vm.formItemLayout,false),[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'couponTypeCode',
                  {
                    getValueFromEvent: _vm.onCouponTypeChanged,
                    rules: [{ required: true, message: '请输入' + this.$t('field.couponTypeName') }]
                  }
                ]),expression:"[\n                  'couponTypeCode',\n                  {\n                    getValueFromEvent: onCouponTypeChanged,\n                    rules: [{ required: true, message: '请输入' + this.$t('field.couponTypeName') }]\n                  }\n                ]"}],attrs:{"mode":"default","name":"couponTypeName","disabled":_vm.data.couponId}},_vm._l((_vm.codeList.couponTypeList),function(item,index){return _c('a-select-option',{key:index,attrs:{"item":item,"value":item.couponTypeCode}},[_vm._v(_vm._s(item.couponTypeCode)+"-"+_vm._s(item.couponTypeName)+" ")])}),1)],1),_c('a-form-item',_vm._b({attrs:{"has-feedback":"","label":_vm.$t('field.couponCode')}},'a-form-item',_vm.formItemLayout,false),[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'couponCodeInput',
                  {
                    rules: _vm.$rules([
                      { required: true, message: '请输入' + this.$t('field.couponCode') },
                      { max: 8, message: this.$t('field.campaignCode') + '长度小于等于8' },
                      {
                        type: 'checkExists',
                        url: '/api/marketing/coupon/checkCouponCodeExists',
                        existIdValue: _vm.data.couponId,
                        checkField: 'couponCode',
                        idFieldName: 'couponId',
                        tableName: 'coupon',
                        checkFieldBaseValue: _vm.data.couponTypeCode
                      }
                    ])
                  }
                ]),expression:"[\n                  'couponCodeInput',\n                  {\n                    rules: $rules([\n                      { required: true, message: '请输入' + this.$t('field.couponCode') },\n                      { max: 8, message: this.$t('field.campaignCode') + '长度小于等于8' },\n                      {\n                        type: 'checkExists',\n                        url: '/api/marketing/coupon/checkCouponCodeExists',\n                        existIdValue: data.couponId,\n                        checkField: 'couponCode',\n                        idFieldName: 'couponId',\n                        tableName: 'coupon',\n                        checkFieldBaseValue: data.couponTypeCode\n                      }\n                    ])\n                  }\n                ]"}],attrs:{"name":"data.couponCodeInput","placeholder":'请输入' + this.$t('field.couponCode'),"maxLength":8},on:{"change":function($event){return _vm.dataModel($event, 'data.couponCodeInput')}}})],1),_c('a-form-item',_vm._b({attrs:{"has-feedback":"","label":_vm.$t('field.couponName')}},'a-form-item',_vm.formItemLayout,false),[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'couponName',
                  {
                    rules: _vm.$rules([
                      { required: true, message: '请输入' + this.$t('field.couponCode') },
                      { max: 100, message: this.$t('field.couponCode') + '长度小于等于100' },
                      {
                        type: 'checkExists',
                        url: '/api/marketing/coupon/checkCouponNameExists',
                        existIdValue: _vm.data.couponId,
                        checkField: 'couponName',
                        idFieldName: 'couponId',
                        tableName: 'coupon'
                      }
                    ])
                  }
                ]),expression:"[\n                  'couponName',\n                  {\n                    rules: $rules([\n                      { required: true, message: '请输入' + this.$t('field.couponCode') },\n                      { max: 100, message: this.$t('field.couponCode') + '长度小于等于100' },\n                      {\n                        type: 'checkExists',\n                        url: '/api/marketing/coupon/checkCouponNameExists',\n                        existIdValue: data.couponId,\n                        checkField: 'couponName',\n                        idFieldName: 'couponId',\n                        tableName: 'coupon'\n                      }\n                    ])\n                  }\n                ]"}],attrs:{"name":"data.couponName","placeholder":'请输入' + this.$t('field.couponName')},on:{"change":function($event){return _vm.dataModel($event, 'data.couponName')}}})],1),_c('a-form-item',_vm._b({attrs:{"has-feedback":"","label":_vm.$t('field.timeLimitType')}},'a-form-item',_vm.formItemLayout,false),[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'timeLimitType',
                  { rules: [{ required: true, message: '请输入' + this.$t('field.timeLimitType') }] }
                ]),expression:"[\n                  'timeLimitType',\n                  { rules: [{ required: true, message: '请输入' + this.$t('field.timeLimitType') }] }\n                ]"}],attrs:{"mode":"default"},model:{value:(_vm.data.timeLimitType),callback:function ($$v) {_vm.$set(_vm.data, "timeLimitType", $$v)},expression:"data.timeLimitType"}},_vm._l((_vm.codeList.timeLimitType),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.label}},[_vm._v("["+_vm._s(item.value)+"] "+_vm._s(item.label)+" ")])}),1)],1),(_vm.data.timeLimitType === '固定期间')?_c('a-form-item',_vm._b({attrs:{"has-feedback":"","label":_vm.$t('field.validTimeRange')}},'a-form-item',_vm.formItemLayout,false),[_c('a-range-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'fixedDT',
                  { rules: [{ type: 'array', required: true, message: '请输入' + this.$t('field.validTimeRange') }] }
                ]),expression:"[\n                  'fixedDT',\n                  { rules: [{ type: 'array', required: true, message: '请输入' + this.$t('field.validTimeRange') }] }\n                ]"}],attrs:{"showTime":"","valueFormat":"YYYYMMDDHHmmss"},model:{value:(_vm.fixedDT),callback:function ($$v) {_vm.fixedDT=$$v},expression:"fixedDT"}})],1):_vm._e(),(_vm.data.timeLimitType === '固定截止时间')?_c('a-form-item',_vm._b({attrs:{"has-feedback":"","label":_vm.$t('field.endTime')}},'a-form-item',_vm.formItemLayout,false),[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'timeLimitDT',
                  { rules: [{ type: 'string', required: true, message: '请输入' + this.$t('field.endTime') }] }
                ]),expression:"[\n                  'timeLimitDT',\n                  { rules: [{ type: 'string', required: true, message: '请输入' + this.$t('field.endTime') }] }\n                ]"}],attrs:{"showTime":"","valueFormat":"YYYYMMDDHHmmss"},model:{value:(_vm.timeLimitDT),callback:function ($$v) {_vm.timeLimitDT=$$v},expression:"timeLimitDT"}})],1):_vm._e(),(_vm.data.timeLimitType === '领取后生效')?_c('a-form-item',_vm._b({attrs:{"has-feedback":"","label":_vm.$t('field.validDateCount')}},'a-form-item',_vm.formItemLayout,false),[(!_vm.validDateFlg)?_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'validDateCount',
                  { rules: [{ required: true, message: '请输入' + this.$t('field.validDateCount') }] }
                ]),expression:"[\n                  'validDateCount',\n                  { rules: [{ required: true, message: '请输入' + this.$t('field.validDateCount') }] }\n                ]"}],attrs:{"addonAfter":_vm.$t('day'),"name":"data.validDateCount ","placeholder":'请输入' + this.$t('field.validDateCount')},model:{value:(_vm.data.validDateCount),callback:function ($$v) {_vm.$set(_vm.data, "validDateCount", $$v)},expression:"data.validDateCount"}}):_vm._e()],1):_vm._e(),(0 && _vm.data.timeLimitType === '领取后生效')?_c('a-form-item',_vm._b({attrs:{"has-feedback":"","label":_vm.$t('field.validDateCount')+'限制'}},'a-form-item',_vm.formItemLayout,false),[_c('a-checkbox',{model:{value:(_vm.validDateFlg),callback:function ($$v) {_vm.validDateFlg=$$v},expression:"validDateFlg"}},[_vm._v("无限制")])],1):_vm._e(),(_vm.data.couponId)?_c('a-form-item',_vm._b({attrs:{"label":_vm.$t('field.couponStatus')}},'a-form-item',_vm.formItemLayout,false),[_vm._v(" "+_vm._s(_vm.data.couponStatus)+" ")]):_vm._e(),_c('SelectMarketplace',{attrs:{"data":_vm.data}})],1),_c('a-tab-pane',{key:"2",attrs:{"forceRender":true,"tab":_vm.$t('title.discountSetting')}},[_c('a-form-item',_vm._b({attrs:{"label":_vm.$t('field.marketingDiscountType')}},'a-form-item',_vm.formItemLayout,false),[_vm._v(" "+_vm._s(_vm.data.marketingDiscountType)+" ")]),(_vm.data.marketingDiscountType === '运费比例')?_c('a-form-item',_vm._b({attrs:{"has-feedback":"","label":_vm.$t('field.shippingFeeAmountPercent')}},'a-form-item',_vm.formItemLayout,false),[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'discount.shippingFeeAmountPercent',
                  {
                    rules: _vm.$rules([
                      { required: true, message: '请输入' + this.$t('field.shippingFeeAmountPercent') },
                      {
                        type: 'maxValue',
                        maxValue: 100,
                        message: this.$t('field.shippingFeeAmountPercent') + '应小于等于100'
                      }
                    ])
                  }
                ]),expression:"[\n                  'discount.shippingFeeAmountPercent',\n                  {\n                    rules: $rules([\n                      { required: true, message: '请输入' + this.$t('field.shippingFeeAmountPercent') },\n                      {\n                        type: 'maxValue',\n                        maxValue: 100,\n                        message: this.$t('field.shippingFeeAmountPercent') + '应小于等于100'\n                      }\n                    ])\n                  }\n                ]"}],attrs:{"type":"number","name":"data.discount.shippingFeeAmountPercent","placeholder":'请输入' + this.$t('field.shippingFeeAmountPercent')},model:{value:(_vm.data.discount.shippingFeeAmountPercent),callback:function ($$v) {_vm.$set(_vm.data.discount, "shippingFeeAmountPercent", $$v)},expression:"data.discount.shippingFeeAmountPercent"}})],1):_vm._e(),(_vm.data.marketingDiscountType === '商品价格比例')?_c('a-form-item',_vm._b({attrs:{"has-feedback":"","label":_vm.$t('field.pricePercent')}},'a-form-item',_vm.formItemLayout,false),[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'discount.pricePercent',
                  {
                    rules: _vm.$rules([
                      { required: true, message: '请输入' + this.$t('field.pricePercent') },
                      { type: 'maxValue', maxValue: 100, message: this.$t('field.pricePercent') + '应小于等于100' }
                    ])
                  }
                ]),expression:"[\n                  'discount.pricePercent',\n                  {\n                    rules: $rules([\n                      { required: true, message: '请输入' + this.$t('field.pricePercent') },\n                      { type: 'maxValue', maxValue: 100, message: this.$t('field.pricePercent') + '应小于等于100' }\n                    ])\n                  }\n                ]"}],attrs:{"type":"number","addon-after":"%","name":"data.discount.pricePercent","placeholder":'请输入' + this.$t('field.pricePercent')},model:{value:(_vm.data.discount.pricePercent),callback:function ($$v) {_vm.$set(_vm.data.discount, "pricePercent", $$v)},expression:"data.discount.pricePercent"}})],1):_vm._e(),(_vm.data.marketingDiscountType === '运费金额')?_c('a-form-item',_vm._b({attrs:{"has-feedback":"","label":_vm.$t('field.shippingFeeAmount')}},'a-form-item',_vm.formItemLayout,false),[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'discount.shippingFeeAmountShow',
                  { rules: [{ required: true, message: '请输入' + this.$t('field.shippingFeeAmount') }] }
                ]),expression:"[\n                  'discount.shippingFeeAmountShow',\n                  { rules: [{ required: true, message: '请输入' + this.$t('field.shippingFeeAmount') }] }\n                ]"}],attrs:{"type":"number","step":"0.01","name":"data.discount.shippingFeeAmount","addon-after":_vm.currencySymbol,"placeholder":'请输入' + this.$t('field.shippingFeeAmount')},model:{value:(_vm.data.discount.shippingFeeAmount),callback:function ($$v) {_vm.$set(_vm.data.discount, "shippingFeeAmount", $$v)},expression:"data.discount.shippingFeeAmount"}})],1):_vm._e(),(_vm.data.marketingDiscountType === '商品价格金额')?_c('a-form-item',_vm._b({attrs:{"has-feedback":"","label":_vm.$t('field.priceAmount')}},'a-form-item',_vm.formItemLayout,false),[_c('a-input-group',{attrs:{"size":"large"}},[_c('a-row',{attrs:{"gutter":8}},[_c('a-col',{attrs:{"span":12}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                        'discount.minTotalAmountShow',
                        {
                          rules: [
                            { required: true, message: '请输入' + this.$t('field.minTotalAmount') } ]
                        }
                      ]),expression:"[\n                        'discount.minTotalAmountShow',\n                        {\n                          rules: [\n                            { required: true, message: '请输入' + this.$t('field.minTotalAmount') }\n                            // { type: 'number', message: this.$t('field.priceAmount')+'应该是数字' }\n                          ]\n                        }\n                      ]"}],attrs:{"min":0,"type":"number","step":"1","prefix":"满","addon-after":_vm.currencySymbol,"name":"data.discount.minTotalAmount","placeholder":'请输入' + this.$t('field.minTotalAmount')},on:{"change":function($event){return _vm.dataModel($event, 'data.discount.minTotalAmount')}}})],1),_c('a-col',{attrs:{"span":12}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                        'discount.priceAmountShow',
                        {
                          rules: [
                            { required: true, message: '请输入' + this.$t('field.priceAmount') } ]
                        }
                      ]),expression:"[\n                        'discount.priceAmountShow',\n                        {\n                          rules: [\n                            { required: true, message: '请输入' + this.$t('field.priceAmount') }\n                            // { type: 'number', message: this.$t('field.priceAmount')+'应该是数字' }\n                          ]\n                        }\n                      ]"}],attrs:{"min":1,"prefix":"减","addon-after":_vm.currencySymbol,"name":"data.discount.priceAmount","placeholder":'请输入' + this.$t('field.priceAmount')},on:{"change":function($event){return _vm.dataModel($event, 'data.discount.priceAmount')}}})],1)],1)],1)],1):_vm._e()],1),_c('a-tab-pane',{key:"4",attrs:{"forceRender":true,"tab":_vm.$t('title.multilingual')}},[_c('MultilingualInfo',{attrs:{"data":_vm.data.multilingualInfoList,"edit":true},on:{"dataChange":_vm.getMultilingualData}})],1)],1)],1),_c('a-form-item',{staticStyle:{"text-align":"center"},attrs:{"wrapperCol":{ span: 24 }}},[_c('a-button',{staticStyle:{"margin-left":"8px"},attrs:{"type":"primary","icon":"save"},on:{"click":_vm.handleCouponSubmit}},[_vm._v(_vm._s(_vm.$t('button.submit')))])],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }