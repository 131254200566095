import request from '@/utils/request'

const baseUrl = '/api'

export function saveCategoryData (parameter) {
  let url = baseUrl + '/goods/category/save'
  if (!parameter.categoryId) {
    url = baseUrl + '/goods/category/create'
  }
  return request({
    url: url,
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}

export function searchCategory (parameter) {
  return request({
    url: baseUrl + '/goods/category/search?categoryCode=' + parameter.categoryCode + '&categoryName=' + parameter.categoryName,
    method: 'post',
    data: parameter
  }).then(res => {
    return {
      data: res.data.list,
      pageNo: res.data.pageInfo.pageNo ? res.data.pageInfo.pageNo : 1,
      totalCount: res.data.pageInfo.totalCount
    }
  })
}

export function listCategory (parameter) {
  return request({
    url: baseUrl + '/goods/category/list',
    method: 'post',
    data: parameter
  }).then(res => {
    return {
      data: res.data
    }
  })
}

export function getCategoryData (parameter) {
  return request({
    url: baseUrl + '/goods/category/get',
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}

export function enableCategory (parameter) {
  return request({
    url: baseUrl + '/goods/category/enable',
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}

export function disableCategory (parameter) {
  return request({
    url: baseUrl + '/goods/category/disable',
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}

export function deleteCategory (parameter) {
  return request({
    url: baseUrl + '/goods/category/delete',
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}
