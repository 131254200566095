import request from '@/utils/request'
let origin = window.location.origin;

let uploadBaseUrl = '';
if(origin.includes('ops-dev.grabie.cn')){
  uploadBaseUrl = 'https://file-ops-dev.grabieshop.com';
} 
else if(origin.includes('ops-test.grabie.cn')){
  uploadBaseUrl = 'https://file-ops-test.grabieshop.com';
}
else if(origin.includes('ops-pre.grabie.cn')){
  uploadBaseUrl = 'https://file-ops-pre.grabieshop.com';
}
else if(origin.includes('ops.grabie.cn')){
  uploadBaseUrl = 'https://file-ops.grabieshop.com';
}

export function imgUpload (parameter) {
  return request({
    url: uploadBaseUrl + '/api/file/image/upload',
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}